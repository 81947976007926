import FrameTypes, { REPLY_TYPE } from '../frameTypes'

export type ACK_REPLY = {
  acked: boolean
  cleanBuffer: number[]
}

/**
 * Look for ping ACK
 * @param newData Incoming data
 * @returns If acked and clean buffer for user readable printout
 */
export const lookForACK = (newData: number[]): ACK_REPLY => {
  const PING_ACK = new TextEncoder().encode(
    `${REPLY_TYPE.ACK}${FrameTypes.NOOP.id}`
  )
  const WRITE_ACK = new TextEncoder().encode(
    `${REPLY_TYPE.ACK}${FrameTypes.WRITE_TO_SCRATCHPAD.id}`
  )

  let buffer: number[] = []
  let position = 0

  buffer.push(...newData)
  let acked = false

  for (let i = 0; i < buffer.length; i += 1) {
    if (buffer[i] !== PING_ACK[position] && buffer[i] !== WRITE_ACK[position]) {
      position = 0
    } else {
      position += 1

      if (position === PING_ACK.length || position === WRITE_ACK.length) {
        buffer = buffer.slice(i + 1)
        acked = true
        break
      }
    }
  }

  position = 0

  return {
    acked: acked,
    cleanBuffer: [],
  }
}
