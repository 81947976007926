import molecule from '@dtinsight/molecule'

import { REPLY_TYPE, Frame } from './frameTypes'

import { isWhatPercentOf, bytesToNumber } from './helpers'

import { EVENT_TYPE } from './serialProvider'

import {
  prepareReadLuaPayload,
  SCRATCHPAD_SIZE,
  LUA_SCRIPT_MODE,
  sendFrame,
} from './serialApi'

import { AcriosSerial } from './serialDriver'

import { NotificationLevel, showNotification } from '../common'

//
// Constants
//
let CMD_RX_TIMEOUT = 1200
let MAX_ERROR_COUNT = 25


// Define a getter for CMD_RX_TIMEOUT
Object.defineProperty(window, 'CMD_RX_TIMEOUT', {
 get: function() {
    return CMD_RX_TIMEOUT;
 },
 set: function(value) {
    if (typeof value !== 'number') {
      console.error('CMD_RX_TIMEOUT must be a number');
      return;
    }
    CMD_RX_TIMEOUT = value;
 },
 enumerable: true,
 configurable: true
});

// Define a getter and setter for MAX_ERROR_COUNT
Object.defineProperty(window, 'MAX_ERROR_COUNT', {
 get: function() {
    return MAX_ERROR_COUNT;
 },
 set: function(value) {
    if (typeof value !== 'number') {
      console.error('MAX_ERROR_COUNT must be a number');
      return;
    }
    MAX_ERROR_COUNT = value;
 },
 enumerable: true,
 configurable: true
});

//
// Enums
//
enum LUA_STATE {
  IDLE = 'idle',
  WAIT_FOR_TX_DELAY_ELAPSED = 'waitForTxDelayElapsed',
  WAIT_FOR_RECEPTION = 'waitForReception',
  DONE = 'done',
}

enum RECEIVE_TYPE {
  ASCII = 'ascii',
  BIN = 'bin',
}

enum FSM_MODE {
  READ = 'read',
  WRITE = 'write',
}

//
// Types
//
type LuaCounters = {
  bytesToSkip: number
  bytesToRead: number
  bytesRead: number
  bytesTotal: number
  scratchpad: number
}

export type FsmContext = {
  mode: FSM_MODE
  scriptMode: LUA_SCRIPT_MODE
  rxBuffer: number[]
  literalAnswers: string[]
  selectedAnswer: string
  selectedAnswerPosition: number
  receiveType: RECEIVE_TYPE
  errorsCount: number
  lastFrameIndex: number
  binaryExpectedLength: number
  binaryReceivedMessage: number[]
  xz: number[]
  lua: string
}

type LuaTimestamps = {
  rx: number
  tx: number
}

//
// Variables
//
let fsmContext: FsmContext
let luaCounters: LuaCounters
let luaTimestamps: LuaTimestamps
let luaState: LUA_STATE
let batchFrames: Frame[]
let currFrameIndex: number

//
// Prepare for reading of Lua script
//
const luaFSMStart = (mode: FSM_MODE, frames: Frame[]): void => {
  fsmContext = {
    mode: mode,
    scriptMode: LUA_SCRIPT_MODE.TEXT,
    rxBuffer: [],
    literalAnswers: [],
    selectedAnswer: '',
    selectedAnswerPosition: 0,
    receiveType: RECEIVE_TYPE.ASCII,
    errorsCount: 0,
    lastFrameIndex: -1,
    binaryExpectedLength: 0,
    binaryReceivedMessage: [],
    xz: [],
    lua: '',
  }

  luaCounters = {
    bytesToSkip: -1,
    bytesToRead: 0,
    scratchpad: 0,
    bytesRead: 0,
    bytesTotal: 0,
  }

  luaTimestamps = {
    rx: 0,
    tx: 0,
  }

  batchFrames = frames
  currFrameIndex = 0
  luaState = LUA_STATE.IDLE
}

//
// Main FSM function
//
const luaFiniteStateMachine = async (
  port: React.MutableRefObject<AcriosSerial>,
  eventType: EVENT_TYPE,
  onFinishedCallback: Function,
  eventData?: number[]
) => {
  const now = new Date().valueOf()
  const last = luaState

  switch (luaState) {
    case LUA_STATE.IDLE:
      switch (eventType) {
        case EVENT_TYPE.PERIODIC:
          // DIE here if the errors count reaches an unbearable level causing ultimate depression
          if (fsmContext.errorsCount > MAX_ERROR_COUNT) {
            console.error(`Too many errors! (${fsmContext.errorsCount})`)
            showNotification(
              'luaState',
              `Too many errors received!`,
              undefined,
              NotificationLevel.ERROR
            )
            luaState = LUA_STATE.DONE
            onFinishedCallback()
            break
          }

          // check if there are some other frames to send
          if (!batchFrames[currFrameIndex]) {
            if (fsmContext.mode === FSM_MODE.READ) {
              // when reading, prepare next batch of frames
              luaCounters.scratchpad += 1
              batchFrames = prepareReadLuaPayload(luaCounters.scratchpad)
              currFrameIndex = 0
            } else if (fsmContext.mode === FSM_MODE.WRITE) {
              // when writing, end successfully
              console.log('[LUA WRITE] ✅ Finished')
              luaState = LUA_STATE.DONE
              onFinishedCallback()
              showNotification(
                'luaState',
                'Script write complete, restarting the device. The device will reconnect shortly.',
                'pass-filled'
              )
              break
            }
          }

          const currFrame = batchFrames[currFrameIndex]

          fsmContext.rxBuffer = [] // drop the receive buffer
          fsmContext.selectedAnswer = ''
          fsmContext.selectedAnswerPosition = 0
          fsmContext.literalAnswers = []
          if (fsmContext.lastFrameIndex !== currFrameIndex) {
            fsmContext.errorsCount = 0 // for counting errors in row
            fsmContext.lastFrameIndex = currFrameIndex
          }

          for (const reply of currFrame.replies) {
            if ([REPLY_TYPE.ACK, REPLY_TYPE.ERR].includes(reply)) {
              fsmContext.literalAnswers.push(`${reply}${currFrame.id}`)
            }
          }

          if (fsmContext.literalAnswers.length > 0) {
            fsmContext.receiveType = RECEIVE_TYPE.ASCII
          } else {
            fsmContext.receiveType = RECEIVE_TYPE.BIN
            fsmContext.binaryExpectedLength = currFrame.binaryExpectedLength
          }

          const res = sendFrame(currFrame, null, false, port.current)
          if (res) {
            luaTimestamps.tx = now
            luaState = LUA_STATE.WAIT_FOR_TX_DELAY_ELAPSED
          }
          break

        case EVENT_TYPE.ON_RECEIVED:
          // ignore any received data
          break
      }
      break

    case LUA_STATE.WAIT_FOR_TX_DELAY_ELAPSED:
      switch (eventType) {
        case EVENT_TYPE.PERIODIC:
          if (now - luaTimestamps.tx >= batchFrames[currFrameIndex].txDelay) {
            luaState = LUA_STATE.WAIT_FOR_RECEPTION
          }
          break

        case EVENT_TYPE.ON_RECEIVED:
          fsmContext.rxBuffer.push(...eventData)
          break
      }
      break

    case LUA_STATE.WAIT_FOR_RECEPTION:
      switch (eventType) {
        case EVENT_TYPE.PERIODIC:
          if (now - luaTimestamps.tx >= CMD_RX_TIMEOUT) {
            console.log(
              `timed out after ${
                now - luaTimestamps.tx
              }, timeout set to ${CMD_RX_TIMEOUT}`
            )
            fsmContext.errorsCount += 1
            luaState = LUA_STATE.IDLE
            return
          }
          break

        case EVENT_TYPE.ON_RECEIVED:
          fsmContext.rxBuffer.push(...eventData)
          break
      }

      if (fsmContext.receiveType === RECEIVE_TYPE.ASCII) {
        let receptionDone = false
        for (const b of fsmContext.rxBuffer) {
          // answer to look for is selected, try to match character by character
          if (fsmContext.selectedAnswer !== '') {
            // if non matching character is encountered, the process of searching the
            // selected answer to match with is restarted by clearing the selectedAnswer variable
            if (
              b !==
              fsmContext.selectedAnswer.charCodeAt(
                fsmContext.selectedAnswerPosition
              )
            ) {
              fsmContext.selectedAnswer = ''
            } else {
              fsmContext.selectedAnswerPosition += 1
              if (
                fsmContext.selectedAnswerPosition ===
                fsmContext.selectedAnswer.length
              ) {
                receptionDone = true
                break
              }
            }
          }

          if (fsmContext.selectedAnswer === '') {
            // iterate over possible ASCII answers and try to match with the first character
            for (const litVal of fsmContext.literalAnswers) {
              if (b === litVal.charCodeAt(0)) {
                fsmContext.selectedAnswer = litVal
                fsmContext.selectedAnswerPosition = 1

                // address the corner case, be a good programmer: case if the selected answer is single character
                if (fsmContext.selectedAnswer.length === 1) {
                  receptionDone = true
                }
                break
              }
            }
          }
        }

        // we have the whole matching ASCII expressing, decide what to do based on the value
        if (receptionDone) {
          if (fsmContext.selectedAnswer.startsWith('ACK')) {
            // we have received an acknowledge, proceed to the next frame in row and go to idle state
            luaState = LUA_STATE.IDLE
            currFrameIndex += 1
            fsmContext.errorsCount = 0

            if (fsmContext.mode === FSM_MODE.WRITE) {
              showNotification(
                'luaState',
                `${isWhatPercentOf(
                  currFrameIndex,
                  batchFrames.length
                )}% - writing in progress...`,
                'desktop-download'
              )
            }
          } else if (fsmContext.selectedAnswer.startsWith('ERR')) {
            // we have received an error - increment the error count
            luaState = LUA_STATE.IDLE
            fsmContext.errorsCount += 1
          }
        }
      } else if (fsmContext.receiveType === RECEIVE_TYPE.BIN) {
        let receivedCorrect = false
        if (fsmContext.rxBuffer.length >= fsmContext.binaryExpectedLength) {
          // we have enough bytes for processing - now, try to calculate the checksum for
          // every possible shift in the received answer, since there can be some noise on
          // the line causing bad characters to be inserted before or after a correct answer
          const shifts =
            fsmContext.binaryExpectedLength - fsmContext.rxBuffer.length + 1

          for (let shift = 0; shift < shifts; shift++) {
            let chs = 0
            let position = 0

            const rxChs =
              fsmContext.rxBuffer[shift + fsmContext.binaryExpectedLength - 2]
            const rxChsInverted =
              fsmContext.rxBuffer[shift + fsmContext.binaryExpectedLength - 1]

            // before calculating the checksum - check that at the end of the frame, the first and the
            // second byte are the checksum and checksum binary inverted, if not, drop
            if (rxChs !== (~rxChsInverted & 0xff)) {
              continue
            }

            for (
              position = shift;
              position < shift + fsmContext.binaryExpectedLength - 2;
              position++
            ) {
              chs += fsmContext.rxBuffer[position]
            }

            if ((chs & 0xff) === rxChs) {
              receivedCorrect = true
              fsmContext.binaryReceivedMessage = fsmContext.rxBuffer.slice(
                shift,
                shift + fsmContext.binaryExpectedLength - 2
              )
              break
            }
          }
        }

        if (fsmContext.mode === FSM_MODE.READ && receivedCorrect) {
          const buffer = fsmContext.binaryReceivedMessage

          if (luaCounters.bytesToSkip === -1) {
            if (
              (buffer[0] === 0xfe ||
                buffer[0] === 0x01 ||
                buffer[0] === 0x02) &&
              buffer[1] === 0xfe &&
              buffer[2] === 0xfe &&
              buffer[3] === 0xfe
            ) {
              // determine mode of Lua script
              let scriptMode

              switch (buffer[0]) {
                case 0x01:
                  scriptMode = LUA_SCRIPT_MODE.XZ
                  break
                case 0x02:
                  scriptMode = LUA_SCRIPT_MODE.EXZ
                  break
                default:
                  scriptMode = LUA_SCRIPT_MODE.TEXT
                  break
              }
              fsmContext.scriptMode = scriptMode

              // read length of Lua script's binary part
              let binaryLength = bytesToNumber(buffer, 4, 4)
              binaryLength +=
                scriptMode === LUA_SCRIPT_MODE.XZ ||
                scriptMode === LUA_SCRIPT_MODE.EXZ
                  ? 12
                  : 8

              if (!isNaN(binaryLength)) {
                // read length of XZ compressed Lua script
                if (
                  scriptMode === LUA_SCRIPT_MODE.XZ ||
                  scriptMode === LUA_SCRIPT_MODE.EXZ
                ) {
                  luaCounters.bytesToRead = bytesToNumber(buffer, 8, 4)
                  luaCounters.bytesTotal = binaryLength
                } else {
                  // assume length of raw text script from binary
                  luaCounters.bytesRead = 0
                  luaCounters.bytesTotal = binaryLength * 1.5
                }

                if (binaryLength === 0) {
                  // TODO: no binary part of script found
                }

                // prepare frames to read text part of script
                // first scratchpad is read so it should be skipped
                let scratchpadsToSkip = Math.floor(
                  binaryLength / SCRATCHPAD_SIZE
                )
                luaCounters.bytesToSkip =
                  (binaryLength / SCRATCHPAD_SIZE - scratchpadsToSkip + 1) *
                  SCRATCHPAD_SIZE
                luaCounters.scratchpad = scratchpadsToSkip - 1

                console.log(
                  `Binary Lua script has ${binaryLength} Bytes. Text part starts from scratchpad ${
                    binaryLength / SCRATCHPAD_SIZE
                  }. Bytes to skip ${luaCounters.bytesToSkip}.`
                )
                if (
                  scriptMode === LUA_SCRIPT_MODE.XZ ||
                  scriptMode === LUA_SCRIPT_MODE.EXZ
                ) {
                  console.log(
                    `XZ compression is used. Bytes to read ${luaCounters.bytesToRead}.`
                  )
                }
              } else {
                console.error(`Could not find lua script!`)
                showNotification(
                  'luaState',
                  `Could not find lua script!`,
                  undefined,
                  NotificationLevel.ERROR
                )
                luaState = LUA_STATE.DONE
                onFinishedCallback()
              }
            } else {
              console.error(`Lua header not found!`)
              showNotification(
                'luaState',
                `Lua header not found!`,
                undefined,
                NotificationLevel.ERROR
              )
              luaState = LUA_STATE.DONE
              onFinishedCallback()
            }
          }

          let pass
          let shouldContinue = true
          for (let i = 0; i < buffer.length; i++) {
            const element = buffer[i]

            if (luaCounters.bytesToSkip > 0) {
              luaCounters.bytesToSkip -= 1
            } else if (luaCounters.bytesToSkip === 0) {
              switch (fsmContext.scriptMode) {
                case LUA_SCRIPT_MODE.EXZ:
                case LUA_SCRIPT_MODE.XZ:
                  if (luaCounters.bytesToRead > 0) {
                    fsmContext.xz.push(element)
                    luaCounters.bytesToRead -= 1
                    luaCounters.bytesRead += 1
                  }

                  if (luaCounters.bytesToRead <= 0) {
                    // signal that reading is finished for now until XZ is asynchronously decompresssed
                    onFinishedCallback()
                    // prepare stream for XZ library
                    shouldContinue = false
                    break
                  }
                  break

                case LUA_SCRIPT_MODE.TEXT:
                  if (element === 0x00) {
                    shouldContinue = false
                    luaCounters.bytesRead = luaCounters.bytesTotal
                    // TODO: add device type to file name
                    if (fsmContext.lua.length > 0) {
                      const name = `readout_${Date.now()}.lua`

                      showNotification(
                        'luaState',
                        `Script read complete into ${name}`
                      )

                      //Open a new tab and dump the data into the new tab
                      molecule.event.EventBus.emit(
                        'ImportLuaReadout',
                        fsmContext.lua
                      )
                    } else {
                      console.error(`Did not read anything!`)
                      showNotification(
                        'luaState',
                        `Did not read anything!`,
                        undefined,
                        NotificationLevel.ERROR
                      )
                    }
                    break
                  } else if (element > 0 && element < 127) {
                    luaCounters.bytesRead += 1
                    fsmContext.lua += String.fromCharCode(element)
                  }
                  break
              }
            }
          }

          luaState = LUA_STATE.IDLE

          if (shouldContinue === true) {
            currFrameIndex += 1
            fsmContext.errorsCount = 0

            showNotification(
              'luaState',
              `${isWhatPercentOf(
                luaCounters.bytesRead,
                luaCounters.bytesTotal
              )}% - reading in progress...`,
              'desktop-download'
            )
          } else {
            console.log('[LUA READ] Data successfully read from device ✅')
            luaState = LUA_STATE.DONE
            onFinishedCallback()
            molecule.event.EventBus.emit('luaReadDone', fsmContext)
          }
        }
        // DO NOT clear the receive buffer after processing!
        // we could be in the middle of a correct answer incoming!
      }
      break
  }

  if (last !== luaState) {
    console.log(`Change ${last} -> ${luaState}`)
  }
}

export { FSM_MODE, luaFSMStart, luaFiniteStateMachine }
